<template>
  <div
    style="
      background-color: #171726;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
  >
    <back-nav-last-page
      class="title_top"
      :icon="'img/backw.png'"
      :textColor="'#ffffff'"
      :backColor="'none'"
    />
    <div class="input_content">
      <div class="input_box">
        <input
          v-model="phone"
          class="input_item"
          type="number"
          autofocus
          placeholder="请输入手机号码"
        />
        <div id="money" class="money" @click="getSMSCode" ref="popups">
          {{ countStart ? count + "秒后可重新获取" : "获取验证码" }}
        </div>
      </div>
      <div class="input_box_">
        <input
          v-model="sms_code"
          class="input_item"
          type="number"
          placeholder="请输入验证码"
        />
      </div>
      <div class="btn_box_">
        <div class="btn_item" @click="bindPhone">获取授权</div>
      </div>
      <div id="captcha"></div>
      <div class="mask" v-if="showMask">
        <div class="modal_content">
          <div class="modal_title">账号列表</div>
          <div class="modal_tip">
            <van-radio-group v-if="accountList.length > 0" v-model="userToken">
              <van-radio
                :name="item.token"
                v-for="(item, index) in accountList"
                :key="index"
              >
                <img class="user_face" :src="item.head_portrait" alt="" />
                <span>{{ item.nickname }}</span>
              </van-radio>
            </van-radio-group>
            <div v-else class="modal_tip">未查询到您当前手机号码下的账号。</div>
          </div>
          <div class="modal_btn">
            <div class="modal_btn_item cancel" @click="showMask = false">取消</div>
            <div v-if="userToken!=''" class="modal_btn_item enter" @click="toWalletIndex">登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import BackNavLastPage from "@/components/backNavLastPage.vue";
import { getSmsCodeLogin, phoneLogin } from "@/api/wallet";
export default {
  name: "bindPhone",
  components: {
    BackNavLastPage,
  },
  data() {
    return {
      countStart: false,
      count: 60,
      sms_code: "",
      phone: "",
      str: "",
      captchaIns: null,
      timer: null,
      showMask: false,
      accountList: [],
      userToken: ""
    };
  },
  created() {
    // console.log(this.initNECaptcha)
  },
  mounted() {
    // 若使用降级方案接入
    // initNECaptcha 替换成 initNECaptchaWithFallback
    let that = this;
    initNECaptcha(
      {
        captchaId: "848c089919be41cda0aed1ca4dad4620",
        element: "#captcha",
        mode: "popup",
        width: "320px",
        apiVersion: 2,

        onVerify: (err, data) => {
          // 当验证失败时, 内部会自动 refresh 方法, 无需手动再调用一次
          if (err) return;
          // 以下为业务侧逻辑
          console.log(data.validate);
          that.doSmsSend(data.validate);
        },
      },
      function onload(instance) {
        that.captchaIns = instance;
      },
      function onerror(err) {
        // 初始化失败后触发该函数, err 对象描述当前错误信息
        console.log(err);
      }
    );
  },
  methods: {
    bindPhone() {
      if (this.phone == "") {
        this.$toast.fail("请输入手机号码");
        return false;
      }
      if (!this.validatePhoneNumber(this.phone)) {
        this.$toast.fail("请输入正确的手机号码");
        return false;
      }
      if (this.sms_code == "" || this.sms_code.length != 4) {
        this.$toast.fail("请输入4位数验证码");
        return false;
      }
      let params = {
        phone: this.phone,
        sms_code: this.sms_code,
      };
      phoneLogin(params).then(
        (res) => {
          if (res.code == 10000) {
            this.accountList = res.data;
            if (this.accountList.length > 0) {
              this.userToken = this.accountList[0].token;
            }
            this.showMask = true;
          }
        },
        (error) => {
          console.log(error);
          this.$toast.fail(error);
        }
      );
    },
    validatePhoneNumber(str) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      return reg.test(str);
    },
    getSMSCode() {
      if (this.countStart) {
        return false;
      }
      if (this.phone == "") {
        this.$toast.fail("请输入手机号码");
        return false;
      }
      if (!this.validatePhoneNumber(this.phone)) {
        this.$toast.fail("请输入正确的手机号码");
        return false;
      }
      this.captchaIns && this.captchaIns.verify();
    },
    doSmsSend(data) {
      let params = {
        captcha: data,
        phone: this.phone,
        sms_type: 4,
      };
      getSmsCodeLogin(params).then((res) => {
        if (res.code == 10000) {
          this.countStart = true;
          this.receiveCode();
        }
      });
    },
    receiveCode() {
      this.timer = setInterval(() => {
        this.count--;
        if (this.count <= 0) {
          this.countStart = false;
          this.count = 60;
          this.captchaIns.refresh();
          clearInterval(this.timer);
        }
      }, 1000);
    },
    toWalletIndex() {
      if (this.userToken != "") {
        localStorage.setItem("access_token", this.userToken);
        this.$router.push({
          path: "/myIncome",
        });
      }else{
        this.$toast.fail('请选择账号')
      }
    },
  },
};
</script>
  <style scoped>
.input_content {
  padding: 0 40px 0 40px;
}

.input_box {
  padding: 0 40px 0 40px;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.04);
  margin-top: 60px;
}

.input_box_ {
  padding: 0 40px 0 40px;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.04);
  margin-top: 30px;
}

.input_item {
  border: none;
  display: block;
  height: 100%;
  font-size: 24px;
  background: transparent;
  color: #999999;
  user-select: auto;
}

.money {
  font-size: 24px;
  color: #05C5FB;
  text-align: right;
  flex-grow: 1;
}

.btn_item {
  width: 100%;
  height: 80px;
  background : linear-gradient(to bottom, #02C3FD 0, #61FEAD 100%);
  color: #111111;
  line-height: 80px;
  text-align: center;
  font-size: 28px;
  border-radius: 40px;
  margin-top: 150px;
}
.mask {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_content {
  width: 80%;
  height: 35%;
  background-color: #ffffff;
  padding: 0 20px 0 20px;
  position: relative;
}
.modal_title {
  color: #111111;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}
.modal_tip {
  font-size: 24px;
  color: #111111;
  margin-top: 30px;
}
.modal_btn {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  border-top: 1px solid #efefef;
}
.modal_btn_item {
  flex: 1;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
}
.cancel {
  border-right: 1px solid #efefef;
}
.enter {
  color: #409eff;
}
.user_face {
  width: 60px;
  height: 60px;
}
</style>